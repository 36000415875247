import * as d3 from "d3";
import { useEffect, useRef } from "react";


const AudioVizGraph = () => {

  const svgRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);

  // const applyNoiseGate = (data, threshold) => {
  //   const gatedData = new Uint8Array(data.length);
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i] < threshold) {
  //       gatedData[i] = 0; // Set values below the threshold to 0 (silence)
  //     } else {
  //       gatedData[i] = data[i];
  //     }
  //   }
  //   return gatedData;
  // };

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = 800;
    const height = 50;

    // Request access to microphone
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;

        // Create an analyser node
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;

        // Connect microphone stream to analyser
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        // Set analyser node options
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        // Render function to visualize audio
        const render = () => {
          analyser.getByteFrequencyData(dataArray);

          // const gatedData = applyNoiseGate(dataArray, 20);

          const normalizedData = dataArray.map(d => (d / 255) * height);

          // Clear previous visualization
          svg.selectAll('*').remove();

          // Draw visualization
          svg.selectAll('rect')
            .data(normalizedData)
            .enter()
            .append('rect')
            .attr('x', (_, i) => (i * width) / bufferLength)
            .attr('y', d => height - d)
            .attr('width', width / bufferLength - 1)
            .attr('height', d => d)
            .style('fill', 'steelblue');

          requestAnimationFrame(render);
        };

        render();
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });

    // Cleanup function
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  return (
    <svg ref={svgRef} width={500} height={50}></svg>
  );
}

export default AudioVizGraph