import frequency from "../frequency.svg";
import logo from "../logo2.svg";
import AudioVizGraph from "./AudioVizGraph";

import {
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer,
} from "microsoft-cognitiveservices-speech-sdk";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./transcription.css";
const config = require("../config/config");

const API_KEY = config.SPEECH_CONFIG.API_KEY;
const API_LOCATION = config.SPEECH_CONFIG.REGION;

const STT_URL =
  "https://azure.microsoft.com/en-us/products/cognitive-services/speech-to-text/";

// this will be used for continuous speech recognition
const sdk = require("microsoft-cognitiveservices-speech-sdk");
const speechConfig = SpeechConfig.fromSubscription(API_KEY, API_LOCATION);

// recognizer must be a global variable
let recognizer;

function Transcription() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [input, setInput] = useState("");
  const [history, setHistory] = useState("");
  const [plainString, setPlainString] = useState("");
  const [messages, setMessages] = useState([]);
  const [recognisedText, setRecognisedText] = useState("");
  const [recognisingText, setRecognisingText] = useState("");

  const [isRecognising, setIsRecognising] = useState(false);
  const [roles, setRoles] = useState("");
  const [summary, setSummary] = useState("");

  let result = "";

  const textRef = useRef();
  const toggleListener = () => {
    if (!isRecognising) {
      startRecognizer();
      setRecognisedText("");
    } else {
      stopRecognizer();
    }
  };
  useEffect(() => {
    var constraints = {
      video: false,
      audio: {
        channelCount: 1,
        sampleRate: 16000,
        sampleSize: 16,
        volume: 1,
      },
    };
    const getMedia = async (constraints) => {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        createRecognizer(stream);
      } catch (err) {
        /* handle the error */
        alert(err);
        // console.log(err)
      }
    };

    getMedia(constraints);

    return () => {
      console.log("unmounting...");
      if (recognizer) {
        stopRecognizer();
      }
    };
  }, [roles]);

  // this function will create a speech recognizer based on the audio Stream
  // NB -> it will create it, but not start it
  const createRecognizer = (audioStream) => {
    // configure Azure STT to listen to an audio Stream
    const audioConfig = AudioConfig.fromStreamInput(audioStream);

    // recognizer is a global variable
    recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    //empty str to collect paragraph
    let conversation = "";
    recognizer.recognizing = (s, e) => {
      // uncomment to debug
      // console.log(`RECOGNIZING: Text=${e.result.text}`)
      setRecognisingText(e.result.text);
      textRef.current.scrollTop = textRef.current.scrollHeight;
    };

    recognizer.recognized = (s, e) => {
      setRecognisingText("");
      if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
        // setTimeout(() => {

        // // uncomment to debug
        console.log(`RECOGNIZED: Text=${e.result.text}`);
        conversation += e.result.text;
        setRecognisedText((recognisedText) => {
          if (recognisedText === "") {
            return `${e.result.text} `;
          } else {
            return `${recognisedText}${e.result.text}`;
          }
        });

        textRef.current.scrollTop = textRef.current.scrollHeight;
        // }, 5000);
      } else if (e.result.reason === sdk.ResultReason.NoMatch) {
        // console.log("NOMATCH: Speech could not be recognized.",conversation)
        if (conversation) {
          // console.log(`CONVERSATION: ${conversation}`)
          sendMessage();
        }
        conversation = "";
      }
    };

    const sendMessage = async () => {
      // if (input.trim() === '') return;
      // console.log(roles,'rolesString state');
      const prompt = {
        role: "user",
        content:
          `As a conversation classifier, your task is to organize an unstructured paragraph that contains statements from the given roles: ${roles}. Label each statement with the corresponding roles and structure the conversation in a readable format with clear distinctions. Assume the conversation is well-formed and alternates between the roles. Your response should solely focus on organizing the conversation, avoiding any addition of extra content. The provided paragraph is as follows:` +
          conversation,
      };
      console.log(prompt.content, "prompt content<--");
      setMessages([...messages, prompt]);
      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          Authorization: `Bearer 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`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [...messages, prompt],
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          const res = data.choices[0].message.content;
          // console.log(res)
          // setHistory([...history,res])
          setMessages((messages) => [
            ...messages,
            {
              role: "assistant",
              content: res,
            },
          ]);
          setHistory((history) => [...history, "\n" + res]);
          // var htmlString = "<h1><b>test</b></h1>";
          // setPlainString(history.replace(/<[^>]+>/g, ''));
          // console.log(history)
          // const cHistory = history.split('#')
          // console.log(cHistory)
          setInput("");
        });
      // };
    };
    recognizer.canceled = (s, e) => {
      console.log(`CANCELED: Reason=${e.reason}`);

      if (e.reason === sdk.CancellationReason.Error) {
        console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
        console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
        console.log(
          "CANCELED: Did you set the speech resource key and region values?"
        );
      }
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = (s, e) => {
      console.log("\n    Session stopped event.");
      recognizer.stopContinuousRecognitionAsync();
    };
  };

  // this function will start a previously created speech recognizer
  const startRecognizer = () => {
    setHistory("");
    recognizer.startContinuousRecognitionAsync();
    setIsRecognising(true);
  };

  // this function will stop a running speech recognizer
  const stopRecognizer = () => {
    setIsRecognising(false);
    recognizer.stopContinuousRecognitionAsync();
  };

  // const [formControls, setFormControls] = useState([
  //   { id: 1, value: "" }, // Initial form control
  // ]);

  return (
    <div>
      <header className="App-header">
        <div className="items-row">
          <div className="imgplc">
            <img src={logo} alt="img" />
          </div>

          <div className="content-Plc">
            <div style={{ fontWeight: "700" }}>Speech to Text Conversion</div>
          </div>
        </div>
      </header>

      <div className="transcription-container">
        <div className="live-transcription card-layout">
          <div>
            <div className="text-heading-large">Live Transcription</div>

            <div
              className="img-transcription"
              style={{ display: "flex", justifyItems: "space-between" }}
            >
              <img src={frequency} />
            </div>
          </div>
          <div>
            {isRecognising && (
              <div className="audio-viz-graph">
                <AudioVizGraph></AudioVizGraph>
              </div>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant={isRecognising ? "secondary" : "primary"}
              onClick={() => toggleListener()}
            >
              {isRecognising ? "Stop" : "Start"}
            </Button>
          </div>
        </div>

        <div className="roles card-layout">
          <Form>
            <Form.Group className="margin-row">
              <Form.Control
                as="textarea"
                placeholder="The transcription will go here"
                value={`${recognisedText}${recognisingText}`}
                readOnly
                style={{ minHeight: "500px", resize: "none" }}
                ref={textRef}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Transcription;
